<template>
  <div class="gallery-more-block">
    <div class="container gallery-container">
      <div class="row mb-4">
        <div class="col-md-4" v-if="this.$flats[this.id.toString()].visualisations.plans">
          <img :src="'/assets/images/plan-small/'+this.$flats[this.id.toString()].planID+'.png'" class="gallery-plan-thumb" />
        </div>
        <div class="col-md-8 gallery-specifications-column">
          <div class="row">
            <div class="col-md-8">
              <router-link to="/gallery" class="comeback-link">Повернутися до галереї</router-link>
              <!--  py-1 px-3 -->
              <h1 class="main-title" v-if="this.$flats[this.id.toString()].title">{{ this.$flats[this.id.toString()].title }}</h1>
              <h1 class="main-title" v-else v-on:click="removeTodo">Житло {{ id }}</h1>
            </div>
            <div class="col-md-4">
              <div class="button-dropdown" v-if="this.$flats[this.id.toString()].planID">
                <div class="button-dropdown-header gent-button" @click="toggleDropdown($event)">
                  <span>Перейти</span> до планування
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                  <i class="fa fa-angle-up" aria-hidden="true"></i>
                </div>

                <div class="button-dropdown-content" >
                  <router-link :to="'/plan/floor/5/apartment/' + this.id" class="button-dropdown-link">Поверх 5</router-link>
                  <router-link :to="'/plan/floor/4/apartment/' + this.id" class="button-dropdown-link">Поверх 4</router-link>
                  <router-link :to="'/plan/floor/3/apartment/' + this.id" class="button-dropdown-link">Поверх 3</router-link>
                  <router-link :to="'/plan/floor/2/apartment/' + this.id" class="button-dropdown-link">Поверх 2</router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="gallery-specifications-area">
            <span class="specifications-tag" v-for="(areaData, areaDataIndex) in this.$flats[this.id.toString()].areas" v-bind:key="areaDataIndex">{{ areaData.name }} <strong v-html="areaData.value + (areaData.unit ? ' ' + areaData.unit : '') "></strong></span>
          </div>
        </div>
      </div>
      <CoolLightBox
        :items="galleryImages"
        :index="index"
        @close="index = null">
      </CoolLightBox>
      <div v-masonry transition-duration="0.6s" item-selector=".mansory-item" class="row images-wrapper">
          <div v-masonry-tile class="mansory-item col-sm-6 col-lg-4 mb-4" v-for="(item, imageIndex) in gallerySmallImages" v-bind:key="imageIndex" @click="index = imageIndex">
            <div class="card">
              <img :src="item" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import {VueMasonryPlugin} from 'vue-masonry';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
// import '../shared.js'


Vue.use(VueMasonryPlugin)

export default {
  components: {
    CoolLightBox
  },
  // mounted() {
  //   // this.currentFlat = $route.params.id;
  //   console.log(id);
  // },
  props: ['id'],
  mounted() {
    this.currentFlat = this.$flats[this.id.toString()]
    console.log(this.currentFlat)
  },
  methods: {
    removeTodo() {
      // this.currentFlat = this.$flats[this.id.toString()]
      // console.log(this.currentFlat)
      // .forEach(element => element + 'agdfsg' )
      // this.currentFlat = this.$flats[this.id.toString()]
      // console.log(this.$flats[this.id.toString()].visualisations.items)
      console.log(this.$flats[this.id.toString()].visualisations.items.map(element => this.$linksRules.visualisationsLarge + this.id + '/' + element + this.$flats[this.id.toString()].visualisations.format ))
      console.log(this.sharedVal)
      console.log(this)
    },
    toggleDropdown (event) {
        event.currentTarget.classList.toggle('is-active')
    }
  },
  computed: {
    galleryImages() {
      return this.$flats[this.id.toString()].visualisations.items.map(element => this.$linksRules.visualisationsLarge + this.id + '/' + element + this.$flats[this.id.toString()].visualisations.format )
    },
    gallerySmallImages() {
      return this.$flats[this.id.toString()].visualisations.items.map(element => this.$linksRules.visualisationsSmall + this.id + '/' + element + this.$flats[this.id.toString()].visualisations.format )
    }
  },
  data() {
    return {
      // containerId: 1,
      currentFlat: {},
      // shared,
      index: null
    }
  }
}
</script>

<style lang="scss">
.image {
  height: 200px;
}
.button-dropdown {
  position: relative;
  &-header {
    cursor: pointer;
    position: relative;
    text-overflow: ellipsis;
    width: 100%;
    i.fa {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .3s;
      &.fa-angle-up {
        opacity: 0;
      }
    }
    &.is-active {
      i.fa {
        &.fa-angle-up {
          opacity: 1;
        }
        &.fa-angle-down {
            opacity: 0;
        }
      }
      + .button-dropdown-content {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity .3s;
    visibility: hidden;
    position: absolute;
    z-index: 3;
    width: 100%;
    border: 1px solid #000;
    border-top: 0;
    background: #fff;
    .button-dropdown-link {
      display: block;
      position: relative;
      width: 100%;
      color: #000;
      padding: 10px 15px;
      text-decoration: none;
      &:hover {

        background: #000;
        color: #fff;

      }
    }
  }
}
</style>
